<template>
  <div class="bg-dark vh-100">
    <div v-if="rpcClient.state.failed" class="bg-danger z-1 connection-loss-bar">
      <div class="container text-center p-2">
        <p class="font-weight-bold text-white mb-0"><translate>No connection to server.</translate></p>
      </div>
    </div>
    <SearchInterface v-if="rpcClient.session.token"></SearchInterface>
    <LoginForm v-else></LoginForm>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import SearchInterface from './components/SearchInterface.vue'
import LoginForm from './components/LoginForm.vue'
import {rpcClient} from '@/api/Vue3WebsocketClient'

@Options({
  components: {
    SearchInterface, LoginForm
  }
})
export default class App extends Vue {
  rpcClient = rpcClient
}
</script>

<style lang="scss">
@import "node_modules/elly-bs4/sass/variables.scss";
@import "node_modules/elly-bs4/elly.scss";
@import "styles/fontawesome/fontawesome.scss";
@import "styles/fontawesome/solid.scss";
@import "styles/fontawesome/regular.scss";
@import "styles/loading-button.scss";
@import "node_modules/@vueform/multiselect/themes/default.scss";

/*for Samsung devices with crazy narrow screens*/
.responsive {
  font-size: 75%;
}

@media screen and (min-width: 370px) {
  .responsive {
    font-size: 90%;
  }
}

@media screen and (min-width: 1900px) {
  .responsive {
    font-size: 100%;
  }
}

.connection-loss-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1001;
}

.multiselect-option.is-pointed {
  color: #1c1b3c;
  background-color: rgba(0, 0, 0, 0.07) !important;
}
.multiselect-option.is-selected {
  color: #1c1b3c;
  background-color: #E1E4F2 !important;
}
.multiselect-tag {
  background: #47BBC7;
}
.multiselect-input {
  border: none;
  border-bottom: #1C1B3C solid 2px;
  font-weight: 600;
  font-size: 0.8rem;
  cursor: text;
  position: relative;
  z-index: 1;
  background-color: rgba(28, 27, 60, 0.1);
  height: calc(1.625em + 1.7rem + 4px);
  color: white;
}
.multiselect.is-disabled .multiselect-input {
  cursor: not-allowed;
}
.multiselect-clear {
  background-color: transparent;
}
.animated-input input.form-control, .btn, .card, .animated-input.ai-filled:before, .v3dp__datepicker input, .multiselect:before, textarea, .custom-checkbox .custom-control-label:before {
  border-radius: 3px;
}
.btn:before {
  border-radius: 1px;
}
.animated-input input:disabled {
  background: #f9f9f9;
  cursor: not-allowed;
}
.btn:disabled {
  cursor: not-allowed;
}
.btn:disabled:before {
  visibility: hidden;
}
textarea {
  border: none;
  border-bottom: #1C1B3C solid 2px;
  font-weight: 600;
  font-size: 0.8rem;
  cursor: text;
  position: relative;
  z-index: 1;
  background-color: rgba(28, 27, 60, 0.1);
}
.Vue-Toastification__toast--success {
  background-color: #81C17E !important;
}
.Vue-Toastification__toast--error {
  background-color: #E94B50 !important;
}
</style>
