<template>
  <div ref="buttons">
    <div class="row d-flex flex-row-reverse justify-content-center">
      <div class="col-auto pl-1 pr-1" v-if="isAudit">
        <button class="btn btn-outline-danger btn-raised tooltip-btn" @click="deleteModal.toggle">
          <i class="fa fa-trash"></i>
          <span class="tooltiptext"><translate>Delete</translate></span>
        </button>
      </div>
      <div class="col-auto pl-1 pr-1">
        <button class="btn btn-outline-primary btn-raised tooltip-btn" @click="showThread">
          <span><i class="fa fa-comments"></i>
            <span class="tooltiptext"><translate>Show Thread</translate></span>
            <span v-if="windowWatcher.width >= 1480" class="d-none d-xl-inline-block">&nbsp;<translate>Thread</translate></span>
          </span>
        </button>
      </div>
      <div class="col-auto pl-1 pr-1">
        <button class="btn btn-outline-primary btn-raised tooltip-btn" @click="permaLink">
          <i class="fa fa-link"></i>
          <span class="tooltiptext"><translate>Link</translate></span>
        </button>
      </div>
      <div class="col-auto pl-1 pr-1">
        <button class="btn btn-outline-primary btn-raised tooltip-btn" @click="toggleSourceView">
          <span v-if="mode === 'source'">
            <i class="fa fa-align-left"></i>
            <span class="tooltiptext"><translate>Text view</translate></span>
            <span v-if="windowWatcher.width >= 1480" class="d-none d-xl-inline-block">&nbsp;<translate>Text</translate></span>
          </span>
          <span v-else-if="mode === 'plain'">
            <i class="fa fa-file-image"></i>
            <span class="tooltiptext"><translate>Email view</translate></span>
            <span v-if="windowWatcher.width >= 1480" class="d-none d-xl-inline-block">&nbsp;<translate>Email</translate></span>
          </span>
          <span v-else>
            <i class="fa fa-expand"></i>
            <span class="tooltiptext"><translate>Source view</translate></span>
            <span v-if="windowWatcher.width >= 1480" class="d-none d-xl-inline-block">&nbsp;<translate>Source</translate></span>
          </span>
        </button>
      </div>
      <div class="col-auto pl-1 pr-1">
        <button class="btn btn-outline-primary btn-raised tooltip-btn" @click="downloadEmail">
          <i class="fa fa-download"></i>
          <span class="tooltiptext"><translate>Download</translate></span>
        </button>
      </div>
      <div class="col-auto pl-1 pr-1">
        <button v-if="windowWatcher.width >= 1024" class="btn btn-outline-primary btn-raised tooltip-btn" @click="$emit('email:print')">
          <i class="fa fa-print"></i>
          <span class="tooltiptext"><translate>Print</translate></span>
        </button>
      </div>
      <div class="col-auto pl-1 pr-1">
        <button class="btn btn-outline-primary btn-raised tooltip-btn" @click="toggleImages">
          <i class="fa fa-image"></i>
          <span v-if="allowImages" class="tooltiptext"><translate>Hide Images</translate></span>
          <span v-else class="tooltiptext"><translate>Show Images</translate></span>
        </button>
      </div>
    </div>
    <Modal ref="deleteModal" size="lg">
      <template v-slot:title>
        <h5 class="modal-title"><translate>Delete email?</translate></h5>
      </template>
      <template v-slot:body>
        <p class="text-dark"><translate>Please enter a reason for deleting this email (e.g. GDPR request):</translate></p>
        <textarea class="w-100" style="min-height: 200px" v-model="deleteComment"></textarea>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click="deleteModal.toggle"><translate>Close</translate></button>
        <LoadingButton class="btn btn-danger" :action="deleteEmail"><translate>Delete</translate></LoadingButton>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import {mailServiceApi} from "@/api/MailServiceApi";
import LoadingButton from "@/components/common/LoadingButton.vue";
import Modal from "@/components/common/Modal.vue";
import {ref} from "vue";
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {rpcClient} from "@/api/Vue3WebsocketClient";
import {queryServiceApi} from "@/api/QueryServiceApi";
import Query from "@/model/Query";
import {windowWatcher} from "@/util/WindowWatcher"

@Options({
  components: {
    LoadingButton, Modal
  },
  props: {
    email: Email,
    state: Object
  },
  emits: [ 'delete', 'email:print', 'email:toggleImages' ]
})
export default class DetailButtons extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  windowWatcher = windowWatcher

  //@ts-ignore
  deleteModal: Modal = ref<Modal | null>(null)
  //@ts-ignore
  buttons: HTMLElement = ref<HTMLElement | null>(null)

  email!: Email
  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  mode: string = 'rich'
  deleteComment: string = ''
  allowImages: boolean = false

  toggleSourceView() {
    if (this.mode === 'rich') {
      this.mode = 'source'
    } else if (this.mode === 'source') {
      this.mode = 'plain'
    } else {
      this.mode = 'rich'
    }
    this.$emit('update:modelValue', this.mode)
  }

  downloadEmail() {
    if (this.email && this.email.digest) {
      window.open('/emails/' + encodeURIComponent(this.email.digest) + '.eml', '_blank')
    }
  }

  permaLink() {
    if (this.email && this.email.digest) {
      const el = document.createElement('textarea')
      el.value = window.location.protocol + '//' + window.location.host + '/' + this.email.digest
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.toast.success(this.i18n.$gettext('Link copied to clipboard.'))
    }
  }

  deleteEmail(): Promise<void> {
    this.$emit('delete')
    if (this.email && this.email.digest) {
      return mailServiceApi._deleteEmail(this.email.digest, this.deleteComment).finally(() => {
        this.deleteModal.toggle()
      })
    } else {
      return Promise.resolve()
    }
  }

  showThread() {
    if (!this.state.queryId || queryServiceApi.getQuery(this.state.queryId)?.threadId !== this.email.threadId) {
      let query: Query = new Query()
      query.threadId = this.email.threadId
      queryServiceApi._startQuery(query).then((queryId: string) => {
        if (this.windowWatcher.width < 1200) this.state.emailDigest = null //Close the modal on mobile
        mailServiceApi.resetState()
        Object.assign(this.state, { queryId: queryId, showSearch: false })
      }).catch(e => {
        this.toast.error(this.i18n.$gettext('Failed to load email thread.'))
      })
    } else if (this.windowWatcher.width < 1200) {
      this.state.emailDigest = null //Close the modal on mobile
    }
  }

  toggleImages() {
    this.allowImages = !this.allowImages
    this.$emit('email:toggleImages')
  }

  get isAudit() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('AUDIT') >= 0)
  }
}
</script>

<style lang="scss">
.button-bar {
  height: 80px
}
.email-view {
  height: calc(100% - 200px)
}
@media screen and (min-width: 1200px) {
  .button-bar {
    height: 80px
  }
  .email-view {
    height: calc(100% - 80px)
  }
}
@media screen and (min-width: 1900px) {
  .button-bar {
    height: 86px
  }
  .email-view {
    height: calc(100% - 86px)
  }
}

.tooltip-btn .tooltiptext {
  visibility: hidden;
  min-width: 100px;
  background-color: #47BBC7;
  color: #fff;
  text-align: center;
  padding: 5px 4px;
  border-radius: 6px;

  position: absolute;
  z-index: 9999;
  top: 125%;
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-btn .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #47BBC7 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-btn:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
